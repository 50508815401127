import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { FaInstagram, FaLock, FaYoutube } from "react-icons/fa"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

const WelcomeSection = styled.section`
  padding: 0 0 56px;

  a {
    color: inherit;
    display: inline-block;
    outline: none;
    padding: 26px 0 0;
    text-decoration: none;
  }

  a:hover .arrow {
    transform: translatex(5px);
  }

  h1 {
    display: inline;
    font-size: 32px;
    line-height: 38px;
  }

  .arrow {
    display: inline-block;
    font-size: 32px;
    line-height: 38px;
    transition: transform 0.2s ease-out;
  }

  @media (min-width: 768px) {
    padding: 0 0 64px;

    a {
      padding: 40px 0 0;
    }

    h1 {
      font-size: 42px;
      line-height: 50px;
    }

    .arrow {
      font-size: 42px;
    }
  }
`

const LinkSection = styled.section`
  border-top: 1px solid;
  padding: 0 0 56px;

  h2 {
    border-top: 8px solid;
    display: inline-block;
    font-size: 32px;
    line-height: 38px;
    margin: 0 0 40px;
    padding: 26px 0 0;
  }

  @media (min-width: 768px) {
    padding: 0 0 64px;

    h2 {
      border-top: 10px solid;
      margin: 0 0 64px;
      padding: 40px 0 0;
    }
  }
`

const Instagram = styled.ul`
  background: hsla(0, 0%, 12%, 1);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style-type: none;

  li {
    flex: 33.33%;
    max-width: calc(33.33% - 1.5px);
  }

  li:nth-child(1n + 4) {
    margin: 2px 0 0;
  }
`

const Youtube = styled.ul`
  background: hsla(0, 0%, 12%, 1);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style-type: none;

  li {
    flex: 50%;
    max-width: calc(50% - 1px);
  }

  li:nth-child(1n + 3) {
    margin: 2px 0 0;
  }
`

const LinkButton = styled.div`
  background: hsla(0, 0%, 12%, 1);
  float: right;
  line-height: 56px;
  position: relative;
  width: 50%;

  a {
    color: hsla(0, 100%, 100%, 1);
    padding: 0 0 0 23px;
    text-decoration: none;
  }

  @media (min-width: 768px) {
    line-height: 90px;

    a {
      padding: 0 0 0 35px;
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
    width: 75%;
  }
`

const OnlyFansButton = styled(LinkButton)`
  background: hsla(194, 100%, 47.8%, 1);
`

const IndexPage = ({ data }) => {
  const igUsername = data.allInstaNode.nodes[0].username
  const channelId = data.allYoutubeVideo.nodes[0].channelId

  return (
    <Layout>
      <SEO title="Home" />
      <WelcomeSection>
        <Img
          fluid={data.helleTrevinoWorldChampionImage.childImageSharp.fluid}
          alt="Helle Trevino – Women's bodybuilding World Champion"
        />
        <Link to="/about">
          <h1>
            Hi. I‘m Helle Trevino. I‘m a two times women‘s bodybuilding world
            champion.<span className="arrow">→</span>
          </h1>
        </Link>
      </WelcomeSection>
      <LinkSection className="clearfix">
        <h2>
          <FaLock style={{ verticalAlign: "text-bottom" }} /> OnlyFans
        </h2>
        <a
          href="https://onlyfans.com/helletrevino"
          rel="noreferrer"
          target="_blank"
        >
          <Img fluid={data.onlyFansImage.childImageSharp.fluid} alt="" />
        </a>
        <OnlyFansButton>
          <a
            href="https://onlyfans.com/helletrevino"
            rel="noreferrer"
            target="_blank"
          >
            Visit OnlyFans →
          </a>
        </OnlyFansButton>
      </LinkSection>
      <LinkSection className="clearfix">
        <h2>
          <FaInstagram style={{ verticalAlign: "text-bottom" }} /> @helletrevino
        </h2>
        <Instagram>
          {data.allInstaNode.nodes.map(node => (
            <li key={node.id}>
              <a
                href={`https://instagram.com/p/${node.id}`}
                rel="noreferrer"
                target="_blank"
              >
                <Img
                  fluid={node.localFile.childImageSharp.fluid}
                  alt={node.caption}
                />
              </a>
            </li>
          ))}
        </Instagram>
        <LinkButton>
          <a
            href={`https://instagram.com/${igUsername}`}
            rel="noreferrer"
            target="_blank"
          >
            Visit Instagram →
          </a>
        </LinkButton>
      </LinkSection>
      <LinkSection className="clearfix">
        <h2>
          <FaYoutube style={{ verticalAlign: "text-bottom" }} /> YouTube
        </h2>
        <Youtube>
          {data.allYoutubeVideo.nodes.map(node => (
            <li key={node.videoId}>
              <a
                href={`https://www.youtube.com/watch?v=${node.videoId}`}
                rel="noreferrer"
                target="_blank"
              >
                <Img
                  fluid={node.localThumbnail.childImageSharp.fluid}
                  alt={node.title}
                />
              </a>
            </li>
          ))}
        </Youtube>
        <LinkButton>
          <a
            href={`https://www.youtube.com/channel/${channelId}`}
            rel="noreferrer"
            target="_blank"
          >
            Watch my videos →
          </a>
        </LinkButton>
      </LinkSection>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexDataQuery {
    allInstaNode(limit: 6, sort: { fields: timestamp, order: DESC }) {
      nodes {
        id
        caption
        username
        localFile {
          childImageSharp {
            fluid(maxWidth: 376, maxHeight: 376, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allYoutubeVideo(limit: 4, sort: { fields: publishedAt, order: DESC }) {
      nodes {
        channelId
        title
        videoId
        localThumbnail {
          childImageSharp {
            fluid(maxWidth: 752) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    helleTrevinoWorldChampionImage: file(
      relativePath: {
        eq: "helle-trevino-women-s-bodybuilding-world-champion.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    onlyFansImage: file(relativePath: { eq: "only-fans.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
